import React, { useState } from "react";
import musicFile from "./thomas.mp3";

import { useMediaQuery } from "@mui/material";
import Desktop from "./Desktop";
import DesktopM from "./DeskMob";
import Navbar from './Navbar'
import Navbarm from './NavbarM'
import BlockChain from './Blockchain'
import BlockChainMobile from './BlockchainMobile'
import Dex from "./Dex";
import DexMobile from "./DexMobile";
import Form from "./Form";
import Unique from "./Unique";
import UniqueMobile from "./UniqueMobile";
import Testimonials from "./Testimonials";
import TestimonialsM from "./TestimonialsMob";
import Faq from './Faq'
import MobileApp from './MobileApp'
import Footer from './Footer'
import FooterMob from './FooterMob'
import AutoPiolet from "./AutoPiolet";


const Home = () => {
  const [showModal, setShowModal] = useState(true);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));


  const handleYesClick = () => {
    // Code to play music
    const audio = new Audio(musicFile);
    audio.loop = true;
    audio.play();
  };



  return (
    <div className="mainContent">


<div className="garden">
      {/* THIS JAVASCRIPT CODE IS FOR Header of the Park Page*/}

      {/* <Slider /> */}
      {!smallScreen && (
        <div className="desk">
   <Navbar/>
   <Desktop/>
       <Dex/>
             <BlockChain/>
<AutoPiolet/>
<MobileApp />
        <Unique/>
               <Testimonials/>
               <Form/>
             <Faq/>
               <Footer/>
           
     </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Desktop View when you press on CustomSlider with ctrl and right arrow you move towards the Slider Page Desktop View*/}
      {smallScreen && (
         <div className="mob">
             <Navbarm/>
       <DesktopM/>
       <DexMobile/>
       <BlockChainMobile/> 
       <AutoPiolet/>
       <MobileApp />
       <UniqueMobile/>
       <TestimonialsM/>
       <Form/>
       <Faq/>
               <FooterMob/>
       </div>
      )}
      {/* THIS JAVASCRIPT CODE IS FOR the Slider used for the Mobile View when you press on MobileSlider with ctrl and right arrow you move towards the Slider Page Mobile View*/}
    </div>
     
       {/* 
        <>  
    <Modal
     backdrop="static"
        show={showModal}
       
        keyboard={false}
        onHide={() => {}}
        className=""
      >
        <
        >
    
     
     
        <div className="sunken-panel w-full ">
        <>
        <br/>
        

        <Grid container spacing={2}>
                <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

                <img  src={boot} style={{width:"70%"}}/>
                </Grid> 
                
                <Grid item md={12} xs={12} lg={12} sm={12} className='centeritall'>

                <div className='centerall'>
        <br/>

       
      
      
       <button className="button-80n "  role="button" style={{fontWeight:"700"}}  onClick={() => {
              handleYesClick();
              setShowModal(false);
            }}>
        Thomas The Tank Engine
       </button>
        </div>
                </Grid>
                </Grid>


        </>
      
     
        <br/>
    </div>
        <>
        <div >        <  >
        
        </> </div>
        
        </>
   
  
        </>
  
      </Modal>
      
      </>
       
       */}
   
   
   
    </div>
  );
};

export default Home;
