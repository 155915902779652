import React from 'react'
import Animation from './Animation.gif'
import { Grid } from '@mui/material'
function Desktop() {
  return (
    <div className='backgroundofheromobile' id='Home'>
<br/><br/>
           <div className='container'>
                       <Grid container spacing={2}>
                       <Grid item md={12} lg={12} xs={12} sm={12}>
<h1 className='herotitle'>  <p className='text_closer'>DISCOVER </p>
 
  <span className='gradient'> UNCHECKABLE CAPITAL</span>
 </h1>
 <br/>
 <p className='heropara'>Alpha Insights, Market Mastery: Forging Six-Figure Realities, in Bull or Bear
</p>
<br/>
<h3 className='underparahero'><span style={{color:" #9f8cf7"}}>SEIZE OPPORTUNITIES, CRAFT PROFITS, UNLEASH INSIDER WISDOM,</span> <br/>ACHIEVE FINANCIAL FREEDOM</h3>
<div className='centeritall'><img src={Animation} style={{width:"200px"}}/></div>
</Grid>

                       </Grid>
           </div>
<br/>
    </div>
  )
}

export default Desktop