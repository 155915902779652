import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from './image/logo.png';
function CollapsibleExample() {
  return (
    <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
      <Container>
        {/* Logo on the left */}
        <Navbar.Brand href="#home">
        <img src={logo} style={{ width: '40px' }} />
        </Navbar.Brand>

        {/* Text in the middle */}
        <div className="d-flex align-items-center justify-content-center">
          <span className="text-white" style={{fontWeight:"700"}}>Uncheckable</span>
        </div>

        {/* Navbar toggle button for mobile */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        <Navbar.Collapse id="responsive-navbar-nav">
          <br/>
          <Nav className="ms-auto">
            {/* Your existing Nav links */}
            <Nav.Link href="#Home" style={{color:"#fff" , textAlign:"center"}}>Home</Nav.Link>
            <Nav.Link href="#About" style={{color:"#fff" , textAlign:"center"}}>About</Nav.Link>
            <Nav.Link href="#Features" style={{color:"#fff" , textAlign:"center"}}>Features</Nav.Link>
            <Nav.Link href="#Web3" style={{color:"#fff" , textAlign:"center"}}>Web3</Nav.Link>
            <Nav.Link href="#Faq" style={{color:"#fff" , textAlign:"center"}}>Faq</Nav.Link>
            <Nav.Link href="#Testimonials" style={{color:"#fff" , textAlign:"center"}}>Testimonials</Nav.Link>
<br/>
            <Nav.Link href='#Join' style={{color:"#fff" , textAlign:"center"}}> <a href='#Join' class="button-34" role="button">WAITLIST</a></Nav.Link>



            {/* Dropdown menu */}
      
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default CollapsibleExample;
