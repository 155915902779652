import React from 'react'
import { Grid } from '@mui/material'
import { BsThreeDotsVertical } from "react-icons/bs";
import p1 from './Blockchain/s1.png'
import p2 from './Blockchain/b2.gif'
import p3 from './Blockchain/b3.png'
function Step1() {
  return (
    <div>
     




















           <br/>











           <div data-aos="zoom-in">     <div> <Grid container spacing={2}>
           <Grid item md={12} lg={12} sm={12} xs={12}>
            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
             
             
              <img src={p1} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={12} lg={12} sm={12} xs={12}>
            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
             
             
           
              
           
              <p className='pricename'> UNCHECKABLE PINGED YOU	
		

              <br/> 
              <p className='pricenamex'>

              OG meme coin name: $BONK, shilled by well-known CT <br/>influencers all across. 


              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={12} lg={12} sm={12} xs={12}>
<div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
<p className='buttonb'>17 February 2023 </p>
            </div>


</Grid>
           </Grid></div></div>

      





           <br/>   <div data-aos="zoom-in">    <div> <Grid container spacing={2}>
           <Grid item md={12} lg={12} sm={12} xs={12}>
            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
             
             
              <img src={p2} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={12} lg={12} sm={12} xs={12}>
            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
             
             
           
              
       
              <p className='pricename'> Buying SOL Token: $BONK	
		

              <br/> 
              <p className='pricenamex'>

             
              Purchasing 7.9B $BONK



              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={12} lg={12} sm={12} xs={12}>
<div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
<p className='buttonb'>Approved </p>
            </div>


</Grid>
           </Grid></div></div>
       



      
          
           <br/>   <div data-aos="zoom-in">  <div> <Grid container spacing={2}>
           <Grid item md={12} lg={12} sm={12} xs={12}>
            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
             
             
              <img src={p3} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={12} lg={12} sm={12} xs={12}>
            <div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
             
             
           
              
         
              <p className='pricename'>  $BONK Sold	
	           	
		

              <br/> 
              <p className='pricenamexs'>

             
              2.88 $COQ Sold For $160,177.00 USD


              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={12} lg={12} sm={12} xs={12}>
<div style={{display:"flex" , textAlign:"center" , justifyContent:"center" }}> 
<p className='buttonb'>20 December 2023 </p>
            </div>


</Grid>
           </Grid></div>
</div>
         


   
      










  





    </div>
  )
}

export default Step1