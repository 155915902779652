import React from 'react'
import { Grid } from '@mui/material';
import Test from './Test'
function Testimonials() {
  return (
    <div className='container bg_test' id='Testimonials'>
        <div className='testimonial_outside'>
<br/><br/>
<div className='container'>
<h1 className='herotitle'> <span style={{color:" #9f8cf7"}}> UNCHECKABLE</span> <br/>TESTIMONIALS 
 </h1>
 <br/><br/>
 <div data-aos="zoom-out-up"><Test/></div>


 <br/><br/>
</div>
<br/><br/>
</div>

      
    </div>
  )
}

export default Testimonials