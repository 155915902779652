import React from 'react'
import { Grid } from '@mui/material'
import FaqDetails from './FaqDetails'
function Faq() {
  return (
    <div className='background_of_faq'>
        <br/>    <br/><br/>
        <div className='container' id='Faq'>
        <div data-aos="fade-up"><Grid item md={12} lg={12} xs={12} sm={12}>

<h1 className='herotitle'>FREQUENTLY ASKED <span style={{color:" #9f8cf7"}}> QUESTIONS</span>
 </h1>
 <br/><br/><br/>
</Grid></div>
        
<Grid item md={12} lg={12} xs={12} sm={12}>
<FaqDetails/>
 <br/>
</Grid>

        </div>
      
        <br/>    <br/><br/>
        </div>
  )
}

export default Faq