import React from 'react'
import { Grid } from '@mui/material'
import Divider from '@mui/material/Divider';
function Desktop() {
  return (<div data-aos="fade-right"> <div className='container' id='About'>
  <Divider variant="middle" className='divider' />
<br/>
<Grid container spacing={2}>
       <Grid item md={12} xs={12} sm={12} lg={12}>
       <div data-aos="fade-up"><h1 className='head_genesis'>THE <span style={{color:" #9f8cf7"}}>GENESIS</span> </h1>  </div>
      
       </Grid>
    
         <Grid item md={6} xs={12} sm={12} lg={6} className=''>
         <div data-aos="fade-right"> <div className='container borderleft'>
  <div className='container'> <h1 className='gen_head_one'>Foundation for Excellence</h1>
  <br/>
  <p className='gen_para_one'>
  Uncheckable: Access elite alpha for consistent, 
  high-level profit prospects. Our Web3 expertise has become a cornerstone 
  for members achieving 7 and 8-figure successes. Armed with advanced tools and programs,
  you’ll expertly navigate diverse opportunities, from stocks, to price errors, and highly 
  valuable collectibles, making each day a calculated move towards success. Our team’s deep
   market insights keep you perfectly aligned with the most lucrative trends, merging financial 
   growth with cutting-edge knowledge.


  </p></div>
  
  </div>         </div>

       </Grid>
   

   
       <Grid item md={6} xs={12} sm={12} lg={6} className=''>
       <div data-aos="fade-right"> <div className='container borderright'>
  <br/>  <br/>
  <button class="button-34" role="button">DREAM TEAM</button>
  <br/>  <br/>
   <h1 className='gen_head_right'>Champions of Insider Wealth Mastery</h1>
  <br/>
  <p className='gen_para_right'>
  Powered by a select team of renowned specialists, 
  each with extensive experience in their unique domains.
   Our experts are celebrated in top publications like Forbes
    and are adept at advising high-net-worth individuals, bringing 
    a wealth of knowledge and insight to our community. At Uncheckable, 
    we possess the unparalleled ability to foresee market trends with
   pinpoint accuracy, empowering our members to seize profits long before they become 
   common knowledge

  </p>
  </div>    </div>
   
       </Grid>

</Grid>
    </div></div>

   
  )
}

export default Desktop