import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import './styles.css';




import Test1 from './Section 6/Testimonials UNCHKBLE.png'
import Test2 from './Section 6/Testimonials UNCHKBLE 2.png'
import Test3 from './Section 6/Testimonials UNCHKBLE 3.png'
import Test4 from './Section 6/Testimonials UNCHKBLE 4.png'

import Test6 from './Section 6/Testimonials UNCHKBLE 6.png'
// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

export default function App() {
  return (
    <>
      <Swiper
       
        grabCursor={true}
        centeredSlides={true}
        slidesPerView={'auto'}
        spaceBetween={300}
        loop={true}
       
        navigation={true}
        modules={[  Navigation]}
        className="mySwiper"
      >
        <SwiperSlide>
          <img src={Test1} className='imagetest ' />
        </SwiperSlide>
        <SwiperSlide>
        <img src={Test2} />
        </SwiperSlide>
        <SwiperSlide>
        <img src={Test3} />
        </SwiperSlide>
        <SwiperSlide>
        <img src={Test4} />
        </SwiperSlide>
    
        <SwiperSlide>
        <img src={Test6} />
        </SwiperSlide>
        
      </Swiper>
    </>
  );
}
