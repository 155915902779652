import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import logo from './image/logo.png';
import MenuItem from '@mui/material/MenuItem';

const pages = [
  { name: 'Home', link: '#Home' },
  { name: 'About', link: '#About' },
  { name: 'Features', link: '#Features' },
  { name: 'Web3', link: '#Web3' },
  { name: 'Faq', link: '#Faq' },
  { name: 'Testimonials', link: '#testimonials' },
];

function ResponsiveAppBar() {
  const [anchorElNav, setAnchorElNav] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  return (
    <AppBar position="static" style={{ backgroundColor: '#000' }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <img src={logo} style={{ width: '120px' }} />

          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
            >
              {pages.map((page) => (
                <MenuItem key={page.name} onClick={handleCloseNavMenu}>
                  {/* Replace Typography with Link */}
                  <a href={page.link} style={{ textDecoration: 'none', color: 'inherit' }}>
  {page.name}
</a>
                </MenuItem>
              ))}
            </Menu>
          </Box>

          <Typography
            variant="h5"
            noWrap
            component="a"
            href="#app-bar-with-responsive-menu"
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontWeight: 600,
              letterSpacing: '.2rem',
              color: 'inherit',
              textDecoration: 'none',
            }}
            style={{ fontSize: '15px' }}
          >

          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} style={{ justifyContent: 'center' }}>
          <a href='#Home' sx={{ my: 2, color: 'white', display: 'block' }} role="button" style={{color:"#fff" , textDecoration:"none" }}>Home</a> 
          {'\u00A0'}  {'\u00A0'}  {'\u00A0'}
          <a href='#About' sx={{ my: 2, color: 'white', display: 'block' }} role="button" style={{color:"#fff" , textDecoration:"none" }}>About</a>
          {'\u00A0'}  {'\u00A0'}  {'\u00A0'}
          <a href='#Features' sx={{ my: 2, color: 'white', display: 'block' }} role="button" style={{color:"#fff" , textDecoration:"none" }}>Features</a>
          {'\u00A0'}  {'\u00A0'}  {'\u00A0'}
          <a href='#Web3' sx={{ my: 2, color: 'white', display: 'block' }} role="button" style={{color:"#fff" , textDecoration:"none" }}>Web3</a>
          {'\u00A0'}  {'\u00A0'}  {'\u00A0'}
          <a href='#Faq' sx={{ my: 2, color: 'white', display: 'block' }} role="button" style={{color:"#fff" , textDecoration:"none" }}>Faq</a>
          {'\u00A0'}  {'\u00A0'}  {'\u00A0'}
          <a href='#Testimonials' sx={{ my: 2, color: 'white', display: 'block' }} role="button" style={{color:"#fff" , textDecoration:"none" }}>Testimonials</a>
          </Box>

          <Box sx={{ flexGrow: 0 }}>
          <a href='#Join' class="button-34" role="button">WAITLIST</a>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;
