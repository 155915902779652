import React from 'react'
import Steps from './Steps'
import { Grid } from '@mui/material'
function AutoPiolet() {
  return (<div data-aos="fade-up">   <div className='container'> <br/>
  <Grid container spacing={2}>
<Grid item md={6} lg={6} xs={12} sm={12}>
<div className='container borderauto'>
<div className='container'>


<button class="button-34" role="button">SUCCESS ON AUTOPILOT</button>
<br/>  <br/>
<h1 className='gen_head_right'>PROFIT PULSE: YOUR RADAR FOR PRICE GLITCHES AND EXCLUSIVE INVESTMENTS</h1>
<br/>
<p className='gen_para_one'>
As an esteemed Uncheckable member, you'll unlock exclusive 
access to our lightning-quick monitoring programs, operating 24/7
within our vibrant Discord community. These advanced programs will instantly
notify you the moment a highly sought-after item experiences a significant price drop,
 long before the broader public becomes aware of it. Uncheckable members consistently enjoy
  a valuable head start in the dynamic world of investments.


</p>
<br/>
<p className='gen_para_righta' >
Any moment, from Monday sunrise to weekend nights, our monitors deliver non-stop, transformative investment opportunities. Always on, relentlessly pursuing wins, every single day, year-round.


</p>


</div>

</div>    
<br/><br/>


</Grid>
<Grid item md={6} lg={6} xs={12} sm={12} className='centersteps'>
<div className='container borderauto'>
<div className='container'>
<br/>
<Steps/>
<br/> <br/>
</div>
</div>    
<br/><br/>


</Grid>


  </Grid>


  <br/><br/><br/></div></div>
 
  )
}

export default AutoPiolet