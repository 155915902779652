import React from 'react';
import { MDBFooter } from 'mdb-react-ui-kit';
import { Grid } from '@mui/material';
import logo from './image/logo.png'
export default function App() {
  return (
    <MDBFooter className='container'>
<br/><br/>
<Grid container spacing={2}>

 <Grid item md={6} lg={6} xs={12} sm={12}>

  <img src={logo} style={{width:"70px"}}/>
 </Grid>
 <Grid item md={6} lg={6} xs={12} sm={12}>

 <div className='text-center p-3' style={{ backgroundColor: 'rgba(0, 0, 0, 0.2)' , color:"#fff" }}>
        &copy; {new Date().getFullYear()} Copyright:{' '}
        <a className='' >
        UNCHECKABLE CAPITAL All Rights Reserved
        </a>
      </div>
 </Grid>
</Grid>
<br/>
      
    </MDBFooter>
  );
}