import React from 'react'
import { Grid } from '@mui/material'
import { BsThreeDotsVertical } from "react-icons/bs";
import p1 from './Blockchain/b1.png'
import p2 from './Blockchain/b2.gif'
import p3 from './Blockchain/b3.png'
function Step1() {
  return (
    <div>
     




















           <br/>













           <div> <Grid container spacing={2}>
           <Grid item md={2} lg={2} sm={2} xs={2}>
            <div style={{display:"flex"}}> 
             
             
              <img src={p1} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={6} lg={6} sm={6} xs={6}>
            <div style={{display:"flex"}}> 
             
             
           
              
          
              <p className='pricename'> UNCHECKABLE PINGED YOU	
		

              <br/> 
              <p className='pricenamex'>

              $ORDI is building lots of hype around it,mempool picking up. Mint here: "p": "brc-20", "op": "mint", "tick": "ordi", "amt": "1000"


              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='buttonb'>9 March 2023 </p>
            </div>


</Grid>
           </Grid></div>





           <br/>
           <div> <Grid container spacing={2}>
           <Grid item md={2} lg={2} sm={2} xs={2}>
            <div style={{display:"flex"}}> 
             
             
              <img src={p2} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={6} lg={6} sm={6} xs={6}>
            <div style={{display:"flex"}}> 
             
             
           
              
         
              <p className='pricename'> Minting BRC-20 Token: $ORDI				
		

              <br/> 
              <p className='pricenamex'>

             
              Minted 1500 $ORDI


              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='buttonb'>Approved </p>
            </div>


</Grid>
           </Grid></div>



      
          
           <br/>
           <div> <Grid container spacing={2}>
           <Grid item md={2} lg={2} sm={2} xs={2}>
            <div style={{display:"flex"}}> 
             
             
              <img src={p3} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={6} lg={6} sm={6} xs={6}>
            <div style={{display:"flex"}}> 
             
             
           
              
       
              <p className='pricename'> $ORDI Sold						
	           	
		

              <br/> 
              <p className='pricenamexs'>

             
              1500 $ORDI Sold For $78,000


              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='buttonb'>20 December 2023 </p>
            </div>


</Grid>
           </Grid></div>



   
      










  





    </div>
  )
}

export default Step1