import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import MobileStepper from '@mui/material/MobileStepper';
import Button from '@mui/material/Button';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Divider from '@mui/material/Divider';
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
const steps = [
  {
    label: 'Select campaign settings',
    description: <Step1/>,
  },
  {
    label: 'Create an ad group',
    description: <Step2/>,
  },
  {
    label: 'Create an ad',
    description: <Step3/>,
  },
];

export default function TextMobileStepper() {
  const theme = useTheme();
  const [activeStep, setActiveStep] = React.useState(0);
  const maxSteps = steps.length;

  const handleNext = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep + 1) % maxSteps);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => (prevActiveStep - 1 + maxSteps) % maxSteps);
  };

  return (
    <Box sx={{ flexGrow: 1 }} style={{width:"100%"}}>
      <Box >
        {steps[activeStep].description}
      </Box><Divider variant="middle" className='dividern'  />
      <br/>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        className='backgroundofstep_footer'
        activeStep={activeStep}
        nextButton={
          <Button
            size="small"
            className='buttoncolor_step'
            onClick={handleNext}
          >
More
            {theme.direction === 'rtl' ? (
              <KeyboardArrowLeft />
            ) : (
              <KeyboardArrowRight />
            )}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} className='buttoncolor_step'>
            {theme.direction === 'rtl' ? (
              <KeyboardArrowRight />
            ) : (
              <KeyboardArrowLeft />
            )}
            Back
          </Button>
        }
      />
    </Box>
  );
}
