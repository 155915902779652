import React from 'react'
import { Grid } from '@mui/material'
import Blocks from './BlocksMobile'
function Blockchain() {
  return (
    <div className='container' id='Web3'>
      <br/><br/><br/>
      <Grid container spacing={2}>
<Grid item md={12} lg={12} xs={12} sm={12}>
<div className='container borderlefts'>
  <div className='container'>

  <br/>  <br/>
  <button class="button-34" role="button">BLOCKCHAIN</button>
  <br/>  <br/>
   <h1 className='gen_head_right'>Exclusive Alpha Secrets to  Claim Your Spot in Crypto's Top 1%</h1>
  <br/>
  <p className='blo_para_right'>
  Wave farewell to missed crypto booms and embrace the thrill of catching the next 100x. Our team spans from memecoins, NFTS, DAO’s, farming airdrops, staking,  ordinals, and beyond. Always capturing the crypto pulse first before others. Witness how our members have effortlessly escalated a modest investment into a seven-figure fortune in months, all thanks to our unparalleled insights. Join us and be part of the elite who always strike gold in the crypto universe, bear or bull market.


  </p>
  <br/>
  <p className='gen_para_right' style={{textAlign:"center"}}>
  Skip the queue and soar in the digital finance world


  </p>
 
  <p className='gen_para_one' style={{textAlign:"center"}}>
  Chains Vary, Gains Multiply, Informed Endlessly:


  </p> <br/>
  </div>
  
  </div>    
  <br/><br/>


</Grid>


<Blocks/>
      </Grid>


      <br/><br/><br/>
      </div>
  )
}

export default Blockchain