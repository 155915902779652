import React from 'react'
import { Grid } from '@mui/material'
import { BsThreeDotsVertical } from "react-icons/bs";
import p1 from './Blockchain/e1.png'
import p2 from './Blockchain/b2.gif'
import p3 from './Blockchain/b3.png'
function Step1() {
  return (
    <div>
     




















           <br/>













           <div> <Grid container spacing={2}>
           <Grid item md={2} lg={2} sm={2} xs={2}>
            <div style={{display:"flex"}}> 
             
             
              <img src={p1} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={6} lg={6} sm={6} xs={6}>
            <div style={{display:"flex"}}> 
             
             
           
              
           
              <p className='pricename'> UNCHECKABLE PINGED YOU	
		

              <br/> 
              <p className='pricenamex'>

              Bored Ape Yacht Club Seems To  Be Minting Out. Instant reveal and gold + trippy skin are the rarest fur attributes.


              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='buttonb'>1 May 2021</p>
            </div>


</Grid>
           </Grid></div>





           <br/>
           <div> <Grid container spacing={2}>
           <Grid item md={2} lg={2} sm={2} xs={2}>
            <div style={{display:"flex"}}> 
             
             
              <img src={p2} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={6} lg={6} sm={6} xs={6}>
            <div style={{display:"flex"}}> 
             
             
           
              
       
              <p className='pricename'>  Minting Bayc	
		

              <br/> 
              <p className='pricenamex'>

             
              Purchasing for .08 ETH



              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='buttonb'>Approved </p>
            </div>


</Grid>
           </Grid></div>



      
          
           <br/>
           <div> <Grid container spacing={2}>
           <Grid item md={2} lg={2} sm={2} xs={2}>
            <div style={{display:"flex"}}> 
             
             
              <img src={p3} className='steps_imagesbb'/>
              
              

             
            </div>

</Grid>
<Grid item md={6} lg={6} sm={6} xs={6}>
            <div style={{display:"flex"}}> 
             
             
           
              
         
              <p className='pricename'>Bored Ape Sold
	           	
		

              <br/> 
              <p className='pricenamexs'>

             
              Bored Ape Sold For 85 ETH ($185,105.30)<br/>
              Proof of ping:
              <br/>
              <a href='https://etherscan.io/tx/0xbbaa8d7db83210e4aa4c3e4b66bf7bf31d40a5c38d2bad0d8ac7ce1ad7cd11c8' className='proof' style={{color:"#fff"}}>https://etherscan.io/tx/0xbbaa8d7db83210e4aa4c3e4b66bf7bf31d40a5c38d2bad0d8ac7ce1ad7cd11c8</a>

              </p>
              </p>

             
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='buttonb'>31 August 2022</p>
            </div>


</Grid>
           </Grid></div>



   
      










  





    </div>
  )
}

export default Step1