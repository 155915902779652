import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TbArrowBadgeDownFilled } from "react-icons/tb";
const accordionData = [
  { id: 'panel1', icon: <TbArrowBadgeDownFilled style={{color:"#fff" , fontSize:"30px"}} />, heading: 'What is Uncheckable?', 
  content: 'Uncheckable is your comprehensive Discord community, exploring every facet of early investments, high-demand flips, exceptional deals, and the road to lasting financial independence. Bid farewell to missed wealth opportunities for good! Our expert community spans a wide spectrum of lucrative investments, guaranteeing our members reap diverse benefits. The potential for amassing wealth knows no bounds. <br/><br/> Guaranteeing Your Path to Financial Freedom with Proven, Effect',

},

  { id: 'panel2', icon: <TbArrowBadgeDownFilled style={{color:"#fff" , fontSize:"30px"}} />,
   heading: 'How much does Uncheckable cost?',
   content: "Uncheckable is available for $90 per month. Your subscription grants you access to top-tier investment opportunities, private deals, exclusive guides, and our full array of valuable resources, all backed by our expert team. As a bonus, we occasionally reward our members with complimentary months – because at Uncheckable, everyone's a winner." },


   
  { id: 'panel3', icon: <TbArrowBadgeDownFilled style={{color:"#fff" , fontSize:"30px"}} />,
  heading: 'Where can I join?',
  content: "At Uncheckable, we are deeply committed to the success of our members. Currently, access to our Discord community is available exclusively through a waitlist system. It's important to understand that we have a limited number of new member openings each month, and submissions are selected through a randomized process. <br/><br/> To secure your spot, you can access our waitlist application here. Simply provide your email and name, and you could be the next fortunate member to join our profitable universe. " },
  // Add more accordions as needed
];

export default function AccordionExpandIcon() {
  return (
    <div>
      {accordionData.map(({ id, icon, heading, content , content1 }) => (
        <div key={id} style={{ marginBottom: '30px' }}><div data-aos="fade-up"></div>
<div data-aos="fade-up"> <Accordion className='Accordianbg'>
            <AccordionSummary
              expandIcon={icon}
              aria-controls={`${id}-content`}
              id={`${id}-header`}
              className='head_of_faq'
            >
              <Typography className='head_of_faq_font'>{heading}</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className='head_of_faq_p' dangerouslySetInnerHTML={{ __html: content }}/>
        
             
            </AccordionDetails>
          </Accordion></div>
         
        </div>
      ))}
    </div>
  );
}
