import React from 'react'
import { Grid } from '@mui/material'
import { BsThreeDotsVertical } from "react-icons/bs";
import p1 from './Section 4/p7.png'
import p2 from './Section 4/p8.png'
import p3 from './Section 4/p9.png'
function Step1() {
  return (
    <div>
      <div> <Grid container spacing={2}>
           <Grid item md={8} lg={8} sm={8} xs={8}>
            <div style={{display:"flex"}}> 
              <BsThreeDotsVertical className='dots'/>
             
              <img src={p1} className='steps_images'/>
              
              &nbsp; 
              <p className='pricenames'>Apple Ipad A4     <br/>(10th Generation)</p>
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='price'> $50.00 </p>&nbsp;&nbsp;&nbsp;<p className='prices'>  $900.00 </p>
            </div>


</Grid>
           </Grid></div>


           <br/>






           <div> <Grid container spacing={2}>
           <Grid item md={8} lg={8} sm={8} xs={8}>
            <div style={{display:"flex"}}> 
              <BsThreeDotsVertical className='dots'/>
             
              <img src={p2} className='steps_images'/>
              
              &nbsp; 
              <p className='pricenames'>Fat Tire Electric        <br/>Bike GF600 </p>
            </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='price'> $82.34 </p>&nbsp;&nbsp;&nbsp;<p className='prices'> $799.00 </p>
            </div>


</Grid>
           </Grid></div>
          
           <br/>






<div> <Grid container spacing={2}>
<Grid item md={8} lg={8} sm={8} xs={8}>
 <div style={{display:"flex"}}> 
   <BsThreeDotsVertical className='dots'/>
  
   <img src={p3} className='steps_images'/>
   
   &nbsp; 
   <p className='pricenames'>Apple AirPod Pro  <br/> 2nd Generation     </p>
 </div>

</Grid>
<Grid item md={4} lg={4} sm={4} xs={4}>
<div style={{display:"flex" , textAlign:"end" , justifyContent:"end" }}> 
<p className='price'> $0.00 </p>&nbsp;&nbsp;&nbsp;<p className='prices'> $250.00 </p>
 </div>


</Grid>
</Grid></div>

      










           <br/>





    </div>
  )
}

export default Step1