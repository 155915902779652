import React from 'react'
import { Grid } from '@mui/material'
import app from './image/mobileapp.png'
function Blockchain() {
  return (
    <div className='apbg'>
  
      <Grid container spacing={2}>
<Grid item md={12} lg={12} xs={12} sm={12}>
<div className='container '>
<div data-aos="fade-right"> <div className='container'>


<button class="button-34" role="button">ENDLESS SUCCESS</button>
<br/>  <br/>
 <h1 className='gen_head_right'>
Insider Alpha Alerts: Sparking 
Endless Gains with Every Ping</h1>
<br/>
<p className='blo_para_right'>
Navigate the next big wave first with our specially 
crafted app – receive real-time, direct alerts on major moves 
before they become public knowledge. Be at the forefront of every category, 
every moment, embracing the next groundbreaking meta ahead of the crowd. Around the clock, 
whether it's a quiet holiday or the late hours of midnight,
 our Discord channel is buzzing, offering our members exclusive, life-altering alpha


</p>
<br/>
<p className='gen_para_right' style={{textAlign:"center"}}>
Notifications that you'll always look forward to and verified to enhance your financial prosperity


</p>

</div></div>
 
  
  </div>    



</Grid>
<Grid item md={12} lg={12} xs={12} sm={12}>
<div data-aos="fade-up"><div className='container '>
    <img src={app} className='mobile_app'/>
  
  </div>  </div>
  



</Grid>


      </Grid>



      </div>
  )
}

export default Blockchain