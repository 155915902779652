import React, { useRef } from 'react';
import emailjs from '@emailjs/browser';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Grid } from '@mui/material'

const defaultTheme = createTheme();

export default function SignIn() {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_uxwh9uk', 'template_ve675hl', form.current, 'i7-aakDcGsfCklNdf')
      .then((result) => {
        console.log(result.text);
        e.target.reset();
      }, (error) => {
        console.log(error.text);
      });
  };

  return (
    <ThemeProvider theme={defaultTheme} className='zzz' >
      <div data-aos="fade-down"> <Grid item md={12} lg={12} xs={12} sm={12} id='Join'>
        <br /><br /> <br />
        <h1 className='herotitle'>Join <span style={{ color: " #9f8cf7" }}> Waitlist</span></h1>
      </Grid></div>
     
      <div data-aos="fade-up-right">  <Container maxWidth="sm">
        <CssBaseline />
        <div className='container'></div>

        <Box
          sx={{
            marginTop: 4,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Box component="form" ref={form} onSubmit={sendEmail} noValidate sx={{ mt: 1 }}>
            <TextField
              id="filled-multiline-flexible"
              label={<span>Name <span style={{ color: '#9f8cf7' }}>*</span></span>}
              multiline
              name="from_name"
              margin="normal"
              InputProps={{ style: { color: '#797979' } }}
              fullWidth
              InputLabelProps={{ style: { color: '#797979' } }}
              variant="filled"
              className='back_of_field'
            />

            <TextField
              margin="normal"
              fullWidth
              label={<span>Email address <span style={{ color: '#9f8cf7' }}>*</span></span>}
              variant="filled"
              InputProps={{ style: { color: '#797979' } }}
              className='back_of_field'
              InputLabelProps={{ style: { color: '#797979' } }}
              color=""
              name="from_email"
              autoComplete="email"
            />

            <br /> <br />
            <div className='centeritall'>
              <input className="button-34" type="submit" value="Submit" />
            </div>
          </Box>
        </Box>
      </Container></div>
    
    </ThemeProvider>
  );
}
