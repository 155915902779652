import React from 'react';
import { Grid } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import cro from './cr.png'
import card1 from './Section5/InsiderGuides.png';
import card2 from './Section5/Collectibles.png';
import card3 from './Section5/Events.png';
import card4 from './Section5/Web3.png';
import card5 from './Section5/Network.png';
import card6 from './Section5/Deals.png';
import card7 from './Section5/Computer.png';
import card8 from './Section5/Price Error.png';
import card9 from './Section5/FBA.png';
const cardsData = [
  {
    image: card1,
    title: 'Insider Guides',
    titledes:'Unveil Premium Insider Secrets',
    description: ' Our support for your financial growth includes exclusive, easy-to-understand guides on key topics and secret strategies. We equip you with everything needed for financial mastery.',
  },
  {
    image: card2,
    title: 'Collectible Flips',
    titledes:'Elevate Wealth with Our High-Yield Flips.',
    description: ' Master the art of buying retail and selling for profit with our expertise. Learn to trade a range of valuable items, from art to collector\'s watches, and succeed in any market for impressive returns. We guarantee your continuous success in profitable flipping.',
  },
  {
    image: card3,
    title: 'Event Tickets',
    titledes:'Turn Up The Volume On Your Investments.',
    description: ' Step into the world of six-figure earnings with our clear, profitable method. Start with little to no investment in events and shows. Turn ticket codes from just $25+ into $1,500+ gains. In our vibrant community, these lucrative opportunities become your reality.',
  },
  {
    image: card4,
    title: 'WEB 3',
    titledes:'Vision Meets Value In Crypto',
    description: "Our blockchain experts, leading since the 2010s, have a knack for identifying gems like Topshot to Axie Infinity and Bored Ape Yacht Club, all rocketing from $200 to over $450,000+. In the vibrant meme coin arena, we've spectacularly multiplied investments in PEPE, Doge, ShibaInu, Bonk, Time, and more, escalating $150 into $900,000+ for numerous members. Dive into our alphaverse for unmatched WEB3 insights and elite financial gains.",
  },
  {
    image: card5,
    title: 'Network',
    titledes:'The Power Circle',
    description: "Step into our entrepreneurial universe, where dreams intertwine and victories flourish. More than a network, we're a family of changemakers, bridging connections from digital to tangible. Your path to extraordinary success starts here.",
  },
  {
    image: card6,
    title: 'Elite Deals',
    titledes:'Exclusive Offerings and Opportunities',
    description: "Experience unmatched perks at Uncheckable, including daily free gourmet meals, steep discounts on luxury hotels and car rentals, and an incredible 70-100% off on clothing. Plus, enjoy generous cash bonuses.",
  },
  {
    image: card7,
    title: '24/7 Support',
    titledes:'Your questions, your needs-our priority',
    description: "Our dedicated, expert staff is available around the clock to assist and guide you towards your goals, anytime, day or night. We're here to ensure your journey to success is met with unwavering support.    ",
  },
  {
    image: card8,
    title: 'Price Errors',
    titledes:'Never Miss a Discounted Commodity ',
    description: "Count on us to keep you in the loop, so you never miss out on incredible discounts. Our built-in database continually uncovers price glitches from major retailers. Plus, we offer convenient cash-out services, allowing you to purchase items and receive instant payouts",
  },
  {
    image: card9,
    title: 'Much More',
   
  },
];

function Unique() {
  return (
    <div className='neonbg' id='Features'>
    <br /><br />
    <div className='container'>
      <Grid container spacing={2} justifyContent="center">

        <Grid item md={12} lg={12} xs={12} sm={12}>
        <div data-aos="fade-right">  <h1 className='herotitleu'>
            <br />
            <span style={{ color: "#9f8cf7" }} className='neonText'>Unique Advantages</span>
          </h1></div>
        
          <div data-aos="fade-left">   <div className='centeritall neonText'>  <img src={cro} style={{width:"60%"}} className='neonText'/> <br /> <br /></div></div>
       
        
        </Grid>
       
        {cardsData.map((card, index) => (

   
          <Grid item key={index} md={4} lg={4} xs={12} sm={12}>
            <br/> <br/>
          
            <Card  className='animation_bg_unique'>
              <br />
              {index === 8 ? (
                         <div data-aos="zoom-in-down">      <div>
                         {/* Separate styles for the image */}
                         <img
                           style={{ width: "200px", height: "100%", border: "" }}
                           src={card.image}
                           alt={card.title}
                         />
                         {/* Separate styles for the text */}
                         <CardContent>
                           <br />
                           <h5 className='cardhead'>
                             
                           </h5>
                           <p className='cardhead_under'>
                             {card.titledes}
                           </p>
                           {/* You can add additional styles for the description if needed */}
                           <p className='cardhead_under'>
                             {card.description}
                           </p>
                         </CardContent>
                       </div></div>
          
              ) : (
                <div data-aos="zoom-in-down">  <div>
                {/* Styles for other cards */}
                <img
                  style={{ width: "80px", height: "100%" }}
                  src={card.image}
                  alt={card.title}
                />
                <CardContent>
                  <br />
                  <h5 className='cardhead'>
                    {card.title}
                  </h5>
                  <p className='cardhead_under'>
                    {card.titledes}
                  </p>
                  <p className='cardhead_under'>
                    {card.description}
                  </p>
                </CardContent>
              </div></div>
              
              )}
              <br />
            </Card>
            <br />
          </Grid>
        ))}
      </Grid>
    </div>
    <br /><br /><br />
  </div>
  );
}

export default Unique;
